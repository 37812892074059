import React, {useEffect, useState} from 'react';
import { StyleSheet, SafeAreaView, ScrollView, View, Image, Text, Alert, Dimensions} from "react-native";
import * as Colors from "../styles/Colors";
import {Button} from '../components/button.component';
import {getEventById} from '../services/data.service';
import {useParams, useNavigate} from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";

const {width} = Dimensions.get('window');

const EventScreen = ({navigation, route}) => {
    const [event, setEvent] = useState(null);

    let actualDate = new Date();

    const {id} = useParams();
    const navigate = useNavigate();

    /*Recoge un evento por id al entrara a l avista*/
    useEffect(() => {
        getEventById(id).then(res => {
            console.log('Evento recogido con exito', res);
            setEvent(res.data);
        }).catch(err => {
            console.log('ERROR al recoger el evento', err);
        })
    }, []);

    const incrementTimeToSessionDate = (sessionDate, minutes) => {
        let session = new Date(sessionDate);
        session.setTime(session.getTime() + minutes * 60 * 1000);
        console.log('session', session)
        return session;
    }

    /*Te une a la videollamada del evento*/
    const joinToEvent = () => {
        /*Recoge el token*/
        AsyncStorage.getItem('token').then(res => {
            /*Abre una nueva ventana en la que se vera la videollamada, enviando el id de la sesion y el token del usuario*/
            window.open(`https://videochat.growpsy.com/sfu/${event.video_session_id}/${res.split('Bearer ').pop()}/`);
        })
    }

    return (
        event !== null &&
        <SafeAreaView style={styles.safeArea}>
            <ScrollView>
                <View style={styles.container}>
                    <Image style={{width: '90%', height: 400}} source={{uri: 'https://admin.growpsy.com'+event.event_image}}/>

                    <View style={styles.event}>
                        <Text style={styles.title}>{event.title}</Text>
                        <View style={styles.containerDatePlace}>
                            <Text style={{marginTop: 5, fontFamily: 'Montserrat', fontWeight: 500}}>Fecha inicio: {event.date}</Text>
                            <View style={styles.chip}>
                                <Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{event.place === '' ? 'Online' : event.place}</Text>
                            </View>
                            {/*<Text style={{fontFamily: 'Montserrat', fontWeight: 500}}>{event.place === '' ? 'Online' : event.place}</Text>*/}
                        </View>
                        <Text style={styles.description}>{event.description}</Text>
                    </View>

                    { event.opened &&
                        <Button style={styles.button} text={'Unirme al evento'} primary press={() => joinToEvent()}/>
                    }
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default EventScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    event: {
        width: '90%'
    },
    title: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 15,
        fontFamily: 'Montserrat',
        fontweight: 'bold'
    },
    description: {
      fontFamily: 'Montserrat',
      fontWeight: 500
    },
    button: {
        width: '90%',
        marginTop: 30
    },
    containerDatePlace: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 7
    },
    chip: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 200,
        paddingVertical: 3,
        paddingHorizontal: 10,
        borderRadius: 20,
        backgroundColor: '#C3FFEA'
    }
})
