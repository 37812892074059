import React from 'react';
import {
    StyleSheet,
    View,
    Text
} from "react-native";

export const Select = ({title, values, onChangeValue, error}) => {

    return(
        <View style={styles.container}>
            <Text style={styles.title}>{title}</Text>
            <select style={styles.input} onChange={value => onChangeValue(value.target.value)}>
                <option style={styles.option}  value="" selected>Seleccione un perfil</option>
                {
                    values.map((value) => {
                        return(
                           <option style={styles.option} value={value.key}>{value.label}</option>
                        )
                    })

                }
            </select>
            {error !== null ? <Text style={styles.error}>{error}</Text> : null}
        </View>

    )
}

const styles = StyleSheet.create({
    container: {
        width: '90%'
    },
    title: {
        marginBottom: 5
    },
    input: {
        width: '100%',
        height: 42,
        borderRadius: 6,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: '#D8DBDF',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        outlineStyle: 'none'
    },
    option: {
        fontFamily: 'Montserrat',
        fontWeight: 500
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    }
})
