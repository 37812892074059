import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Text, Dimensions} from 'react-native';
import {Input} from '../components/input.component';
import {Button} from '../components/button.component';
//import DatePicker from 'react-native-modern-datepicker';
import * as Colors from '../styles/Colors';
import {createSession, editSession} from '../services/data.service';
import {Loader} from '../components/loader.component';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';

const {width} = Dimensions.get('window');

const NewSessionScreen = props => {
    const [name, setName] = useState(null);
    const [duration, setDuration] = useState(null);
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [loader, setLoader] = useState(false);

    const [errorMessageTitle, setErrorMessageTitle] = useState(null);
    const [errorMessageDuration, setErrorMessageDuration] = useState(null);
    const [errorMessageDate, setErrorMessageDate] = useState(null);
    const [errorMessageHour, setErrorMessageHour] = useState(null);

    const navigate = useNavigate();
    const {id, idChannel} = useParams();
    const location = useLocation();

    /*Al entrar a la vista*/
    useEffect(() => {
        /*Si existe el id edita los estados*/
        if (id !== undefined) {
            setName(location.state.name);
            setDuration(location.state.duration);
            setDate(location.state.date.replace(':00.000+01:00', ''));
        }
    }, []);

    /*Crea una sesion*/
    const newSession = () => {
        setLoader(true);
        /*Vlida que los campos estan correctos*/
        if (validateTitle() === false && validateDuration() === false && validateDate() === false) {
            /*Crea la sesion*/
            createSession(name, idChannel, date, duration).then(res => {
                setLoader(false);
                /*Navega a la pagina de sesiones del grupo en el que se a creado la sesion*/
                navigate(`/sessions/${idChannel}`, {state: {colors: location.state.colors}});
                console.log('Sesion creada con exito', res.data);
            }).catch(err => {
                console.log('ERR', err);
                if (err.response.status === 400) {
                    /*Da error al crear la sesion*/
                    alert('No se ha podido crear la sesion porque uno o varios pacientes no han pagado sesiones');
                } else if (err.response.status === 422) {
                    /*La informacion introducida no es correcta*/
                    setLoader(false);
                    setTimeout(() => {
                        alert('Se ha producido un error, revisa la información introducida.')
                    }, 200)
                }
                setLoader(false);
                console.log('ERROR al crear la sesion', err);
            })
        } else {
            setLoader(false);
        }
    }

    /*Edita la sesion*/
    const modifySession = () => {
        setLoader(true);
        /*Valida que los campos de la sesion son correctos*/
        if (validateTitle() === false && validateDuration() === false && validateDate() === false) {
            /*Edita la sesion*/
            editSession(id, name, date, duration).then(res => {
                setLoader(false);
                /*Navega a la pagina de sesiones del grupo en el que se a creado la sesion*/
                navigate(`/sessions/${location.state.idChannel}`, {state: {colors: location.state.colors}});
            }).catch(err => {
                console.log('ERROR al editar la sesion', err);
            })
        } else {
            setLoader(false);
        }
    }

    /*Vlida el titulo de la sesion*/
    const validateTitle = () => {
        if (name === null) {
            setErrorMessageTitle('Campo obligatorio.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida la duracion de la sesion*/
    const validateDuration = () => {
        if (duration === null) {
            setErrorMessageDuration('Campo obligatorio.')
            return true;
        } else {
            return false;
        }
    }

    /*Valida la fecha de la sesion*/
    const validateDate = () => {
        if (date === null) {
            setErrorMessageDate('Selecciona una fecha por favor.')
            return true;
        } else {
            return false;
        }
    }

    /*const validateHour = () => {
        if (hour === null) {
            setErrorMessageHour('Selecciona una hora por favor.')
            return true;
        } else {
            return false;
        }
    }*/

    return(
        loader ?
            <Loader style={{flex: 1, height: 500}}/>
            :
            <SafeAreaView style={styles.safeArea}>
                <ScrollView>
                    <View style={styles.container}>
                        <Input
                            title={'Nombre de sesion'}
                            value={name}
                            error={errorMessageTitle}
                            getValue={value => {
                                setErrorMessageTitle(null);
                                setName(value);
                            }}
                        />

                        <Input
                            placeholder={'Minutos de duracion'}
                            title={'Duracion de la sesion'}
                            value={duration}
                            keyboardType={'number-pad'}
                            error={errorMessageDuration}
                            getValue={value => {
                                setErrorMessageDuration(null);
                                setDuration(value);
                            }}
                        />

                        <View style={styles.containerCalendar}>
                            <Text>Fecha de la sesion</Text>

                            <input style={styles.dateTimePicker} value={date} type="datetime-local" onChange={value => setDate(value.target.value)} />
                            {errorMessageDate !== null &&
                            <Text style={styles.error}>{errorMessageDate}</Text>
                            }
                            {errorMessageHour !== null &&
                            <Text style={styles.error}>{errorMessageHour}</Text>
                            }
                        </View>

                        <Button style={{width: '90%'}} text={id !== undefined ? 'Editar sesión' : 'Crear sesión'} primary press={() => id !== undefined ? modifySession() : newSession()}/>
                    </View>
                </ScrollView>
            </SafeAreaView>
    )
}

export default NewSessionScreen;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: 50,
        backgroundColor: Colors.default.COLORS.background_light
    },
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 20
    },
    containerCalendar: {
        width: '90%',
        marginVertical: 20,
    },
    calendar: {
        marginTop: 5,
        borderRadius: 6,
        backgroundColor: '#F1F1F1',
        borderWidth: 1,
        borderColor: '#D8DBDF',
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginBottom: 5
    },
    dateTimePicker: {
        height: 42,
        marginTop: 5,
        paddingRight: 15,
        paddingLeft: 15,
        border: '1px solid #D8DBDF',
        borderRadius: 6
    }
})
